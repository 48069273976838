import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = {
    a: "a",
    blockquote: "blockquote",
    code: "code",
    del: "del",
    em: "em",
    h3: "h3",
    hr: "hr",
    li: "li",
    p: "p",
    strong: "strong",
    ul: "ul",
    ..._provideComponents(),
    ...props.components
  };
  return _jsxs(_Fragment, {
    children: [_jsxs(_components.p, {
      children: ["A lot of the initial decisions were learned from mistakes made in ", _jsx(_components.code, {
        children: "blitz-web"
      }), "."]
    }), "\n", _jsx(_components.p, {
      children: _jsxs(_components.strong, {
        children: ["What was wrong with ", _jsx(_components.code, {
          children: "blitz-web"
        }), " to motivate a large refactor/rewrite?"]
      })
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Mainly the developer experience was quite bad with the slow build times and lack of consolidation."
      }), "\n", _jsx(_components.li, {
        children: "Performance was really bad, lots of unnecessary re-rendering, and SSR just wouldn't work on most pages."
      }), "\n", _jsx(_components.li, {
        children: "Difficult to scale, each different title had frontend built from scratch and code-splitting didn't work well, users would have to download and execute code for every game."
      }), "\n"]
    }), "\n", _jsx(_components.p, {
      children: "It was a codebase that the company had inherited from Jon + Jon + Rick (Instant Esports). We tried building on top until we ran into architectural problems."
    }), "\n", _jsx(_components.hr, {}), "\n", _jsxs(_components.p, {
      children: ["In no particular order, things that were problematic in ", _jsx(_components.code, {
        children: "blitz-web"
      }), " that we want to avoid here. Rather technical boring stuff."]
    }), "\n", _jsx(_components.h3, {
      id: "file-naming",
      children: _jsx(_components.a, {
        href: "#file-naming",
        children: "File naming"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["There should be only 2 extensions: ", _jsx(_components.code, {
        children: ".mjs"
      }), " for ES modules, and ", _jsx(_components.code, {
        children: ".jsx"
      }), " for JSX parsing. I would like to keep the folder structure as flat as possible, only 1 level deep, to encourage re-use."]
    }), "\n", _jsx(_components.h3, {
      id: "esbuild",
      children: _jsx(_components.a, {
        href: "#esbuild",
        children: "esbuild"
      })
    }), "\n", _jsx(_components.p, {
      children: "Using a fast build tool like esbuild should save a lot of developer time (re)starting the development server, and speed up deployments."
    }), "\n", _jsx(_components.p, {
      children: "We are opting out of using Vite or other build tools that add another layer on top of esbuild, due to lack of flexibility."
    }), "\n", _jsx(_components.h3, {
      id: "dynamic-import",
      children: _jsx(_components.a, {
        href: "#dynamic-import",
        children: "Dynamic import"
      })
    }), "\n", _jsx(_components.p, {
      children: "We are using native ES modules with dynamic import, in contrast to something like Loadable Components or React.lazy. There are a few reasons:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsxs(_components.li, {
        children: ["Dynamic import to do code splitting is well supported by esbuild, and ", _jsx(_components.em, {
          children: "will"
        }), " be well supported by future tools."]
      }), "\n", _jsx(_components.li, {
        children: "Generic to the programming language (JavaScript), not locked down to one framework (React). It is portable knowledge."
      }), "\n", _jsx(_components.li, {
        children: "Future proofing, as ES modules will continue to be natively supported."
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["To support code splitting by dynamic import, existing solutions are lacking. We have some specific requirements which require implementing our own router. I think there are pros and cons to this approach. react-router is well supported production ready software with a large community, but ", _jsx(_components.del, {
        children: "does not fit with dynamic imports"
      }), " does not have good support for dynamic imports."]
    }), "\n", _jsxs(_components.p, {
      children: ["A quote from ", _jsx(_components.a, {
        href: "https://reactjs.org/docs/code-splitting.html",
        children: "React docs"
      }), ":"]
    }), "\n", _jsxs(_components.blockquote, {
      children: ["\n", _jsx(_components.p, {
        children: "React.lazy and Suspense are not yet available for server-side rendering. If you want to do code-splitting in a server rendered app, we recommend Loadable Components. It has a nice guide for bundle splitting with server-side rendering."
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: ["Loadable components is ", _jsx(_components.a, {
        href: "https://loadable-components.com/docs/code-splitting/",
        children: "predicated on very outdated information"
      }), ":"]
    }), "\n", _jsxs(_components.blockquote, {
      children: ["\n", _jsx(_components.p, {
        children: "Note: The dynamic import() syntax is a ECMAScript (JavaScript) proposal not currently part of the language standard. It is expected to be accepted in the near future."
      }), "\n"]
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.a, {
        href: "https://caniuse.com/es6-module-dynamic-import",
        children: "Dynamic import has been implemented since Chrome 63"
      }), ", released in December 2017."]
    }), "\n", _jsx(_components.p, {
      children: "To support all of the below requires a custom router as of the time of writing:"
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "Native dynamic import"
      }), "\n", _jsx(_components.li, {
        children: "Server-side rendering"
      }), "\n", _jsx(_components.li, {
        children: _jsx(_components.a, {
          href: "https://loadable-components.com/docs/getting-started/",
          children: "Avoid Babel + Webpack"
        })
      }), "\n"]
    }), "\n", _jsx(_components.h3, {
      id: "persistent-state",
      children: _jsx(_components.a, {
        href: "#persistent-state",
        children: "Persistent state"
      })
    }), "\n", _jsxs(_components.p, {
      children: ["Instead of using redux or react-query or other popular solutions, we will use ", _jsx(_components.a, {
        href: "https://github.com/pmndrs/valtio",
        children: "valtio"
      }), " and indexeddb. High level goals:"]
    }), "\n", _jsxs(_components.ul, {
      children: ["\n", _jsx(_components.li, {
        children: "State should be persistent by default. indexeddb is the only option for persistent more than a trivial amount of data, since localStorage has a 5mb quota."
      }), "\n", _jsxs(_components.li, {
        children: ["Least Recently Used (LRU) caching by default, ", _jsx(_components.em, {
          children: "except"
        }), " for data that we want to \"pin\" (such as static data, own user info). Being able to automatically discard info beyond a certain quota is very important for memory management, to keep RAM and disk usage to a minimum."]
      }), "\n", _jsx(_components.li, {
        children: "Low boilerplate. redux forces devs to write reducers, actions, transducers, confusers, etc. react-query has a large API surface area and forces devs to use hooks which aren't accessible or testable outside of react."
      }), "\n"]
    })]
  });
}
export default function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = {
    ..._provideComponents(),
    ...props.components
  };
  return MDXLayout ? _jsx(MDXLayout, {
    ...props,
    children: _jsx(_createMdxContent, {
      ...props
    })
  }) : _createMdxContent(props);
}
export const meta = () => JSON.parse('{"title":[null,"Blitz Docs"],"description":"Docs for Blitz App"}');
